import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private _isErrorThrown = new BehaviorSubject<boolean>(false);
  isErrorThrown$ = this._isErrorThrown.asObservable();

  private _validationErrors = new BehaviorSubject<string[] | undefined>(
    undefined
  );
  validationErrors$ = this._validationErrors.asObservable();

  private _errorMessage = new BehaviorSubject<string | undefined>(undefined);
  errorMessage$ = this._errorMessage.asObservable();

  publishErrorMessage(error: string) {
    this._isErrorThrown.next(true);
    this._errorMessage.next(error);
  }

  publishValidationErrors(errors: string[]) {
    this._isErrorThrown.next(true);
    this._validationErrors.next(errors);
  }

  clearServerErrors() {
    this._isErrorThrown.next(false);
    this._errorMessage.next(undefined);
    this._validationErrors.next(undefined);
  }
}
